import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import Api from "./Api";

export default class Home extends Component {


    render() {
        return (

            <React.Fragment>
              
                <Api/>
            </React.Fragment>

        )
    }

}