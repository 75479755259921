import React from 'react';

const Map =()=>{


    return(
        <>
        <br/>
        <h2>Map</h2>
         
        <br/>
        <br/>
        <iframe src="https://search-frankfurt-kibana-6zr5xrsyvyuigoocp6utdkp3aa.eu-central-1.es.amazonaws.com/_dashboards/goto/6fed1d687f5331f26fae01e45ee08184?security_tenant=BCP" height="600" width="800"></iframe>
        </>
      
    )
}

export default Map;