import logo from './logo.svg';
import './App.css';
import Home from './componentes/Home';
import Router from '../src/componentes/Router';


function App() {
  return (
    <div className="App">
   
    <Router/>
    

    </div>

  );
}

export default App;
