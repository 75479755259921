import { BrowserRouter, Route, Routes } from "react-router-dom";
import Map from '../componentes/Map'
import Header from "./Header";
import Home from "./Home";
const Router = () => {
    return (

        <BrowserRouter>
            <Header />

            <Routes>
                <Route exact path="/" element={<Home />}></Route>

                <Route exact path="/map" element={<Map />}></Route>


            </Routes>


        </BrowserRouter>


    )
}
export default Router;