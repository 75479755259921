import React, { Component } from "react";
import { Link, NavLink, Router } from "react-router-dom";
import lofoFujitsu from '../img/fujitsu.png'
import { useEffect, useState } from 'react';

function Header(props) {

    const logout =()=>{
       
        console.log("logging out")
        localStorage.removeItem("id_token")
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        alert("you have been log-out and will return to login page")
        window.location.replace("https://connect-fujitsu.awsapps.com/start#/");
    
    
    }

    
function parsejwtv2(token) {
    let idtoken = token.split('.')[1];
    return JSON.parse(atob(idtoken));
}




function Checkvalidperiod(tokenobject) {
    // this is for refreshing and logging out
    // modifications needed for the Authtoken post and deletion of the stored IDs
    if (tokenobject) {
        let toeknhere = parsejwtv2(tokenobject)

        if (toeknhere.exp < Date.now() / 1000) {
            let xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    if (this.response.id_token) {
                        localStorage.setItem("id_token", this.response.id_token)
                        localStorage.setItem("access_token", this.response.access_token)
                        localStorage.setItem("refresh_token", this.response.refresh_token)
                    } else {
                        localStorage.removeItem("id_token")
                        localStorage.removeItem("access_token")
                        alert("Session expired please log back in")
                        window.location.replace("https://connect-fujitsu.awsapps.com/start#/");
                    }
                }
            });
            let refreshtoken = localStorage.getItem("refresh_token")
            xhr.open("POST", "https://tlc.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=refresh_token&refresh_token=" + refreshtoken + "&client_id=574vah39bb2ameg9tjr4tp4e8h");
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.responseType = 'json';
            xhr.send();
        } else {
            return true
        }
    }
}


function Login() {

    let currentsite = window.location.href

    let url = new URL(currentsite)
    let code = url.searchParams.get("code")

    if (Checkvalidperiod(localStorage.getItem("id_token"))) {
        return true
    }



    if (code) {
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                if (this.response.id_token) {
                    localStorage.setItem("id_token", this.response.id_token)
                    localStorage.setItem("access_token", this.response.access_token)
                    localStorage.setItem("refresh_token", this.response.refresh_token)


                }
            }
        });
        xhr.open("POST", "https://tlc.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&code=" + code + "&redirect_uri=https://tlc.fujitsu-aws.com/&client_id=574vah39bb2ameg9tjr4tp4e8h");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.responseType = 'json';
        xhr.send();

    }
    else {
       alert("Please make sure you are logged in ")
       window.location.replace("https://connect-fujitsu.awsapps.com/start#/");
     }
}
useEffect(() => {
        
    Login()

}, [])

    return (

       
            <div>
                <nav className="navbar navbar-expand-lg navbar-light p-4 border-header">
                    <div className="container-fluid  container">
                   
                    {/* <a href="https://search-frankfurt-kibana-6zr5xrsyvyuigoocp6utdkp3aa.eu-central-1.es.amazonaws.com/_plugin/kibana/"  target="_blank" className="nav-link text-dark width-toolname">Open search</a> */}
                   
                       
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                
                             
                            </ul>
                           <img className="logo-fujitsu" src={lofoFujitsu}></img>
                        </div>
                    </div>
                    <button className="btn btn-dark btn-logout" onClick={logout}>Log out</button>
                </nav>
            </div>



    )
}

export default Header;