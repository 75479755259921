import { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Header from './Header';
import Swal from "sweetalert2"
import Spinner from './Spinner';


let tipo = 0;

function Country(props) {

    console.log(props)

    return (


        <div className="center text-white mb-3 width-cards-countries bg-success">
            <div className="card-header">
                <h2>{props.region}</h2>
                <h2>{props.ejemplo}</h2>
            </div>
            <div className="card-body">
                <button type="button" className="btn btn-dark m-3" onClick={props.home}>Home</button>
                <button type="button" className="btn btn-dark m-3" onClick={props.test.bind(this, props.region)}>Test: {props.region}</button>
                {tipo === 2 ? <></> : <button type="button" className="btn btn-dark m-3" onClick={props.openRegion.bind(this, props.region)}>Go to {props.region}</button>}

                
            </div>

        </div>



    )
}


function Api() {


    const [countries, setCountries] = useState([]);
    const [tableCountries, setTableCountries] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [isLoading, setLoading] = useState(true)

    const [titulo, setTitulo] = useState("");




    const requestGet = async () => {
        setLoading(true)
        tipo = 0;
        console.log('El tipo de request es de ' + tipo)
        let requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                "Authorization": localStorage.getItem("id_token"),
                "Content-Type": "application/json"
            },
            mode: 'cors'
        };



            if( localStorage.getItem("id_token")){

                fetch("https://bpcendpoint.fujitsu-aws.com/regions", requestOptions)
                .then(response => response.json())
                .then((json => {
    
                    setCountries(json.body)
                    setTableCountries(json.body)
    
    
                    console.log(json)
                    setLoading(false)
                }))
                .catch(error => console.log('error', error));
                
            console.log(countries)
            console.log(tableCountries)
          
            }
        

    }


    const requestGetGdcs = async (e) => {
        setLoading(true)
        console.log(tipo)
        tipo++
        let url;
        if (tipo === 1) {
            url = 'https://bpcendpoint.fujitsu-aws.com/gdc';
        } else if (tipo === 2) {
            url = 'https://bpcendpoint.fujitsu-aws.com/accounts';

        }

        console.log('Este es el e ' + e)
        setTitulo(e)
        console.log("ESTE ES EL TITLE " + titulo)
        console.log("Esta es la region seleccionada " + e)
        console.log('El tipo de request es de ' + tipo)
        let raw = JSON.stringify({
            "ID": e
        });

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": localStorage.getItem("id_token"),
                "Content-Type": "application/json"
            },
            body: raw,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then((json => {

                setCountries(json.body)
                setTableCountries(json.body)


                console.log(json)
                setLoading(false)
            }))
            .catch(error => console.log('error', error));
           
        console.log(countries)
        console.log(tableCountries)


        console.log('Este es e tipo de request ' + tipo)

        console.log('El tipo de request es de ' + tipo)
    }


    let myHeaders1 = new Headers();
    const testGlobally = async (e) => {

        
        myHeaders1.append("Authorization", localStorage.getItem("id_token"));

        console.log('Este es el e ' + e)
        console.log("Esta es la region seleccionada " + e)
        console.log('El tipo de request es de ' + tipo)

        
            let raw = JSON.stringify({
                "ID": "Global"
            });

            let requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": localStorage.getItem("id_token"),
                    "Content-Type": "application/json"
                },
                body: raw,
                redirect: 'follow'
            };

            fetch('https://bpcendpoint.fujitsu-aws.com/initiate', requestOptions)
                .then(response => response.json())
                .then((json => {
                    Swal.fire('Message', json.body, "success")
                }))
                .catch(error => {
                    Swal.fire(error)
                    console.log(error)
                });
        


    }



    const test = async (e) => {

        console.log('Este es el e ' + e)
        console.log("Esta es la region seleccionada " + e)
        console.log('El tipo de request es de ' + tipo)
        let raw = JSON.stringify({
            "ID": e
        });
        console.log(raw)




            let requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": localStorage.getItem("id_token"),
                    "Content-Type": "application/json"
                },
                body: raw,
                redirect: 'follow'
            };

            fetch('https://bpcendpoint.fujitsu-aws.com/initiate', requestOptions)
                .then(response => response.json())
                .then((json => {
                    Swal.fire('Message', json.body, "success")
                }))
                .catch(error => Swal.fire(error));
        


    }




    const handleChange = e => {
        setBusqueda(e.target.value)
        console.log("Busqueda: " + e.target.value)
        filter(e.target.value)
    }





    const filter = (terminoBusqueda) => {
        let resultadosBusquedas = tableCountries.filter((element) => {
            if (element.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
                return element;
            }
        });

        setCountries(resultadosBusquedas)
    }



    useEffect(() => {
        setTimeout(() => {
            requestGet()

               
          }, 1000)
    

    }, [])



    return (

        <div>
            {/* <Header  home={requestGet}/> */}

            <br />
            <br />

            {tipo == 0 ? (<h2 className='titulo'>Regions</h2>) : <></>}
            {tipo != 0 ? (<h2 className='titulo'>{titulo}</h2>) : <></>}

            <br />


            <br />

         
            <div className="containerInput">
                <input
                    className="form-control inputBuscar"
                    value={busqueda}
                    placeholder="Search"
                    onChange={handleChange}
                />
                <button className="btn btn-success">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </div>
           
            <br />
            <br />
            {tipo == 0 ? <button className="btn btn-success btn-lg" onClick={testGlobally}>Test globally</button> : <></>}


            {/* <div className='clearfix'></div> */}
            <div className="table responsive"></div>
            <br />
            
            <br />
            {isLoading ? <Spinner/> : countries.length === 0  ? (<div className='center'>
                <h3 className="text-center">There are not results...</h3>
                <br />
                <button class="btn btn-success" onClick={requestGet}>Go back</button>
            </div>) : (<div className="container-cards-countries">

                {countries.map(country => <Country key={country} region={country} test={test} openRegion={requestGetGdcs} home={requestGet} />)}
            </div>
            ) }
            <br />

{/* 
            {countries.length === 0  ? (<div className='center'>
                <h3 className="text-center">There are not results...</h3>
                <br />
                <button class="btn btn-success" onClick={requestGet}>Go back</button>
            </div>) : (<div className="container-cards-countries">

                {countries.map(country => <Country key={country} region={country} test={test} openRegion={requestGetGdcs} home={requestGet} />)}
            </div>
            )} */}


        </div>



    )
}

export default Api;