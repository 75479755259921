import React from 'react';

const Spinner = () => {
    
    return (
        <div>
            
        <span class="d-flex justify-content-center"><strong>Loading...</strong></span>
        <br/>
 <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                
            </div>

           
        </div>
       
        </div>
       
    )
}


export default Spinner;